<template>
  <p-drawer :steps="notificationSteps()" @on-close="smartRouter.back()">
    <template #1>
      <div class="p-notifications-drawer">
        <p-loading v-if="loading" class="place-items-center" />
        <div v-else class="place-items-center flex-column pb-16">
          <div
            v-for="(item, index) in announcements"
            :key="index"
            class="p-notifications-drawer-item-list py-16 px-8 cursor-pointer"
            @click="openLinkHandler(item?.url)"
          >
            <div class="place-items-start gap-16">
              <div class="p-notifications-drawer-icon br-12">
                <p-icon :icon="'notifications'" />
              </div>
              <div>
                <p class="f-body-16 t-fg-secondary" v-html="item?.title" />
                <p class="f-body-12 t-fg-secondary" v-text="item?.message" />
              </div>
              <p-icon icon="caret-right" size="xs" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </p-drawer>
</template>

<script lang="ts" setup>
const mainStore = useMainStore()

const loading = ref(false)

const openLinkHandler = (link: string | undefined) => {
  link && window.open(link, '_blank')
}

const notificationSteps = () => ({
  1: {
    title: $t('general.announcements'),
    back: true,
    url: '1',
  },
})

const announcements = computed(() => mainStore?.getAnnouncements)

onMounted(() => {
  loading.value = true
  if (announcements.value && announcements.value.length) {
    loading.value = false
  } else {
    mainStore.fetchAnnouncements().then(() => {
      loading.value = false
    })
  }
})
</script>
